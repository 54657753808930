import { getBrowserCookie } from '@moonpig/web-core-cookies'
import { logger } from '@moonpig/web-core-monitoring'
import { deduplicateValidationErrors } from './helpers/dedupe'
import { transformEventPayload } from './schemas/common.schema'
import { EventObject, ManualEventObjectSchema } from './schemaTypes'
import { errorFormatter, printOutErrors } from './utils'

type Value =
  | string
  | Value[]
  | { [key: string]: Value }
  | boolean
  | number
  | undefined

const sanitizeValue = <T extends Value>(value: T): Value => {
  if (typeof value === 'string') {
    return value.replace(/%/g, '')
  }
  if (Array.isArray(value)) {
    return value.map(sanitizeValue)
  }
  if (typeof value === 'object' && value) {
    return Object.entries(value).reduce<{ [key: string]: Value }>(
      (acc, [key, item]) => {
        acc[key] = sanitizeValue(item)
        return acc
      },
      {},
    )
  }
  return value
}

export const trackGAEvent = (event: EventObject) => {
  if (typeof window !== 'undefined') {
    const sanitizedEvent = sanitizeValue(event)
    let result = sanitizedEvent
    const isValidationEnabled = getBrowserCookie('analytics-debug') === '1'
    window.dataLayer = window.dataLayer || []

    try {
      const validationResult = ManualEventObjectSchema.safeParse(sanitizedEvent)

      if (validationResult.success) {
        result = transformEventPayload(validationResult.data) as EventObject

        if (isValidationEnabled) {
          result.validation_data = { status: 'pass' }
        }

        window.dataLayer.push(result)

        return
      }

      if (typeof sanitizedEvent === 'object' && 'ecommerce' in sanitizedEvent) {
        window.dataLayer.push({ event: 'ecom_clear', ecommerce: null })
      }

      if (isValidationEnabled) {
        ;(result as Record<string, unknown>).validation_data = {
          status: 'fail',
        }

        window.dataLayer.push(result)

        let errors = validationResult.error.issues.map(errorFormatter)

        if ((result as { event: string }).event === 'view_item_list') {
          errors = deduplicateValidationErrors(errors)
        }

        errors.forEach(e => {
          window.dataLayer.push({
            event: 'validation_error',

            validation_data: {
              name: (result as { event: string }).event,
              ...e,
            },
          })
        })

        if (process.env.NODE_ENV !== 'production') {
          printOutErrors({ errors, result })
        }
      } else {
        window.dataLayer.push(result)
      }
    } catch (error) {
      window.dataLayer.push(result)
      logger.fixToday('Error occured while validating events', {}, error)
    }
  }
}
